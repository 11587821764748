'use strict';

var $ = require('../internals/export');
var fails = require('../internals/fails');
var createProperty = require('../internals/create-property');
var ISNT_GENERIC = fails(function () {
  function F() {/* empty */}
  return !(Array.of.call(F) instanceof F);
});

// `Array.of` method
// https://tc39.github.io/ecma262/#sec-array.of
// WebKit Array.of isn't generic
$({
  target: 'Array',
  stat: true,
  forced: ISNT_GENERIC
}, {
  of: function of( /* ...args */
  ) {
    var index = 0;
    var argumentsLength = arguments.length;
    var result = new (typeof this == 'function' ? this : Array)(argumentsLength);
    while (argumentsLength > index) createProperty(result, index, arguments[index++]);
    result.length = argumentsLength;
    return result;
  }
});